import { lazy } from "react";

const Routes = [
  {
    path: "/",
    component: lazy(() => import("../loginpages/Login.js")),
    name: "/",
  },
  {
    path: "/login/admin",
    component: lazy(() => import("../loginpages/Login.js")),
  },
  {
    path: "/plans",
    component: lazy(() => import("../plans/planlist.js")),
    name: "/admin",
  },
  {
    path: "/editplan",
    component: lazy(() => import("../plans/planedit.js")),
    name: "/admin",
  },
  {
    path: "/users",
    component: lazy(() => import("../user/userlist.js")),
    name: "/admin",
  },
  {
    path: "/userdetail",
    component: lazy(() => import("../user/userdetail.js")),
    name: "/admin",
  },
  {
    path: "/faq",
    component: lazy(() => import("../faqlist/faqlist.js")),
    name: "/admin",
  },
  {
    path: "/editfaq",
    component: lazy(() => import("../faqlist/editfaq.js")),
    name: "/admin",
  },
  {
    path: "/addfaq",
    component: lazy(() => import("../faqlist/addfaq.js")),
    name: "/admin",
  },
  {
    path: "/airdrop",
    component: lazy(() => import("../Airdrop/airdrop.js")),
    name: "/admin",
  },
  {
    path: "/crewairdrop",
    component: lazy(() => import("../Airdrop/airdropconfirm.js")),
    name: "/admin",
  },
  {
    path: "/shipairdrop",
    component: lazy(() => import("../Airdrop/airdropconfirm.js")),
    name: "/admin",
  },
  {
    path: "/cmslist",
    component: lazy(() => import("../CMS/cmsbox.js")),
    name: "/admin",
  },
  {
    path: "/editcms/:data",
    component: lazy(() => import("../CMS/editcms.js")),
    name: "/admin",
  },
  {
    path: "/editroadmap",
    component: lazy(() => import("../roadmap/editroadmap.js")),
    name: "/admin",
  },
  {
    path: "/roadmaplist",
    component: lazy(() => import("../roadmap/roadmaplist.js")),
    name: "/admin",
  },
  {
    path: "/tabs",
    component: lazy(() => import("../Tabs/TabsList.js")),
    name: "/admin",
  },
  {
    path: "/edittabs",
    component: lazy(() => import("../Tabs/edittabs.js")),
    name: "/admin",
  },
  {
    path: "/addtabs",
    component: lazy(() => import("../Tabs/addtabs.js")),
    name: "/admin",
  },
  {
    path: "/contactlist",
    component: lazy(() => import("../contactus/contactuslist.js")),
    name: "/admin",
  },
  {
    path: "/contactusdetails",
    component: lazy(() => import("../contactus/contactusdetails.js")),
    name: "/admin",
  },
  {
    path: "/Category",
    component: lazy(() => import("../category/categorylist.js")),
    name: "/admin",
  },
  {
    path: "/addcategory",
    component: lazy(() => import("../category/addcategory.js")),
    name: "/admin",
  },
  {
    path: "/editcategory",
    component: lazy(() => import("../category/editcategory.js")),
    name: "/admin",
  },
  {
    path: "/addsubcategory",
    component: lazy(() => import("../category/addsubcategory.js")),
    name: "/admin",
  },
  {
    path: "/subcategorylist",
    component: lazy(() => import("../category/subcategorylist.js")),
    name: "/admin",
  },
  {
    path: "/viewsubcategoryvalue",
    component: lazy(() => import("../category/subvaluelist.js")),
    name: "/admin",
  },
  {
    path: "/addsubcategoryvalue",
    component: lazy(() => import("../category/addsubvalue.js")),
    name: "/admin",
  },
  {
    path: "/mission-planet-list",
    component: lazy(() =>
      import("../gameSetting/missionPlanetLimit/missionPlanet_lists.js")
    ),
    name: "/admin",
  },
  {
    path: "/game-mission-values",
    component: lazy(() => import("../gameSetting/gamevalues.js")),
    name: "/admin",
  },
  {
    path: "/game-settings",
    component: lazy(() => import("../gameSetting/gameSettings.js")),
    name: "/admin",
  },
  {
    path: "/poollist",
    component: lazy(() => import("../pool/poollist.js")),
    name: "/admin",
  },
  {
    path: "/addpool",
    component: lazy(() => import("../pool/addpool.js")),
    name: "/admin",
  },
  {
    path: "/editpool",
    component: lazy(() => import("../pool/editpool.js")),
    name: "/admin",
  },
  {
    path: "/collectionlist",
    component: lazy(() => import("../collection/Collectionlist.js")),
    name: "/admin",
  },
  {
    path: "/addcollection",
    component: lazy(() => import("../collection/Collectionadd.js")),
    name: "/admin",
  },
  // {
  //     path: "/gameassets",
  //     component: lazy(() => import('../gameassets/listgameassets.js')),
  //     name: '/admin'
  // },
  {
    path: "/gameassets",
    component: lazy(() => import("../Gamebuilding/gamelist.js")),
    name: "/admin",
  },
  {
    path: "/addbuilding",
    component: lazy(() => import("../Gamebuilding/addbuilding.js")),
    name: "/admin",
  },
  {
    path: "/viewlevel",
    component: lazy(() => import("../Gamebuilding/viewlevellist.js")),
    name: "/admin",
  },
  {
    path: "/addlevel",
    component: lazy(() => import("../Gamebuilding/addlevel.js")),
    name: "/admin",
  },
  {
    path: "/editlevel",
    component: lazy(() => import("../Gamebuilding/editlevel.js")),
    name: "/admin",
  },
  {
    path: "/collectiontypelist",
    component: lazy(() => import("../collectionTypes/collectionList.js")),
    name: "/admin",
  },
  {
    path: "/editcollectiontype",
    component: lazy(() => import("../collectionTypes/editCollectionType.js")),
    name: "/admin",
  },
  {
    path: "/addcollectiontype",
    component: lazy(() => import("../collectionTypes/addCollectiontype.js")),
    name: "/admin",
  },
  {
    path: "/tokenlist",
    component: lazy(() => import("../tokenPages/tokenList.js")),
    name: "/admin",
  },
  {
    path: "/addtoken",
    component: lazy(() => import("../tokenPages/addToken.js")),
    name: "/admin",
  },
  {
    path: "/shiplist",
    component: lazy(() => import("../ship/shiplist.js")),
    name: "/admin",
  },
  {
    path: "/addship",
    component: lazy(() => import("../ship/addShip.js")),
    name: "/admin",
  },
  {
    path: "/edit-ship",
    component: lazy(() => import("../ship/edit.ship.js")),
    name: "/admin",
  },
  {
    path: "/missionrewardlist",
    component: lazy(() => import("../mission reward/missionrewardList.js")),
    name: "/admin",
  },
  {
    path: "/addmissionreward",
    component: lazy(() => import("../mission reward/addMissionReward.js")),
    name: "/admin",
  },
  {
    path: "/edit-missionreward",
    component: lazy(() => import("../mission reward/edit_mission_reward.js")),
    name: "/admin",
  },
  {
    path: "/bloglist",
    component: lazy(() => import("../blog/bloglist.js")),
    name: "/admin",
  },
  {
    path: "/editblog",
    component: lazy(() => import("../blog/editBlog.js")),
    name: "/admin",
  },
  {
    path: "/addblog",
    component: lazy(() => import("../blog/addblog.js")),
    name: "/admin",
  },
  {
    path: "/addnews",
    component: lazy(() => import("../news/addnews.js")),
    name: "/admin",
  },
  {
    path: "/editnews",
    component: lazy(() => import("../news/editNews.js")),
    name: "/admin",
  },
  {
    path: "/newslist",
    component: lazy(() => import("../news/newslist.js")),
    name: "/admin",
  },
  {
    path: "/sociallist",
    component: lazy(() => import("../socials/socilalist.js")),
    name: "/admin",
  },
  {
    path: "/editsocial",
    component: lazy(() => import("../socials/editsocial.js")),
    name: "/admin",
  },
  {
    path: "/crewlist",
    component: lazy(() => import("../crew/crewlist.js")),
    name: "/admin",
  },
  {
    path: "/addcrew",
    component: lazy(() => import("../crew/addcrew.js")),
    name: "/admin",
  },
  {
    path: "/addpartners",
    component: lazy(() => import("../partnerts/addPartners.js")),
    name: "/admin",
  },
  {
    path: "/partners",
    component: lazy(() => import("../partnerts/partners.js")),
    name: "/admin",
  },
  {
    path: "/addpromobuild",
    component: lazy(() => import("../promobuilds/addpromobuild.js")),
    name: "/admin",
  },
  {
    path: "/promobuildlist",
    component: lazy(() => import("../promobuilds/promobuildlist.js")),
    name: "/admin",
  },
  {
    path: "/addpublish",
    component: lazy(() => import("../Publish/addpublish.js")),
    name: "/admin",
  },
  {
    path: "/publishlist",
    component: lazy(() => import("../Publish/publishlist.js")),
    name: "/admin",
  },
  {
    path: "/edit-publish",
    component: lazy(() => import("../Publish/editpublish.js")),
    name: "/admin",
  },
  {
    path: "/uploadpdf",
    component: lazy(() => import("../pdf/pdfUpload.js")),
    name: "/admin",
  },
  {
    path: "/edit-promo",
    component: lazy(() => import("../promobuilds/edit_promo_build.js")),
    name: "/admin",
  },

  {
    path: "/crew-price",
    component: lazy(() => import("../crewPrice/crewPrice_list.js")),
    name: "/admin",
  },
];

// PdfUpload
export default Routes;
