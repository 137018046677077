const Envname = "stage";
let key = {};
key.KEY = "CardBo@rD1290%6Fine3";
key.ONEDAYINSECONDS = 0;
key.BLOCKS_PER_YEAR = 0;
key.SECRET_KEY = "gallfi@mArt!game";
key.RPAD_ADDRESS = "";
key.ROUTER = "";
key.EMAIL = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
key.MOBILE = /^\d{10}$/;
key.NumOnly = /^\d+$/;
key.PASSWORD =
  /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[^a-zA-Z0-9])(?!.*\s).{8,15}$/;
key.OnlyAlbhabets = /^(?:[A-Za-z]+)(?:[A-Za-z0-9 _]*)$/;
key.notSpecil = /^[a-zA-Z-9]+$/;
key.OnlyAlphSpecial = /^[A-Za-z_@.#&+-]*$/;
key.IPFS = "https://ipfs.io/ipfs/";
key.limit = 50;
key.NumDigitOnly = /[^0-9\.]/g;
key.NumberOnly = /[^0-9]/g;
key.NETWORKCHAIN = ["ethereum", "sepolia"];
key.RARITY = ["common", "uncommon", "rare", "legendary"];
key.MissionType = ["all", "combat", "explore", "mine", "social"];
key.CDN = "https://d2y7h9ktdedpcy.cloudfront.net/";
key.gender = ["male", "female"];
key.COINNAMES = ["eth", "matic", "" ];
key.IMAGEPATH = "promo/";
key.IMAGEPATH_PROMO = "promo/";

if (Envname === "local") {
  key.chainlist = [97, 43113];
  key.versionlist = ["0x61", "0xa869"];
  key.chainId = "0xa869";
  key.COMMON_URL = "http://localhost:4000/v1/common";
  key.BACK_V1 = "http://localhost:4000/v1";
  key.BACK_URL = "http://localhost:4000/v1/front";
  key.AdminAPI = "http://localhost:4000/v1/admin";
  key.CMS = "http://localhost:4000/v1/cms";
  key.EXCHANGE = "http://localhost:4000/v1/exchange";
  key.adminaddress = "0xEA4fE72960C36CA7a9F4E6A107fDfE07a952704E".toLowerCase();
  key.IMG_URL = "https://backend-phoenix.maticz.in";
  key.DefaultImg = "/assets/images/avatar/avatar-01.png";
  key.CoverImg = "https://backend-phoenix.maticz.in/demobanner.jpg";
  key.chain_Id_List = [97, 43113];
  key.DEADADDRESS = "0x000000000000000000000000000000000000dEaD".toLowerCase();
  key.TradeContract ="0xEF747c5efE5e0eb67DCC06896341dfb9DF5f4F29".toLowerCase();
  key.ERC721 = "0x01769d2C31143933cda2680605f4D379c417e793".toLowerCase();
  key.ERC1155 = "0x708390Ff3f96F4F46761319E07c50f0DADC22E66".toLowerCase();
  key.erc20Address = "0x7CAB80ce0E55F46378E493B584eE61aD68878f11".toLowerCase();
  key.RPC_URL = "https://data-seed-prebsc-1-s1.binance.org:8545/";
  key.CHAIN_ID = 97;
  key.chain_Id_List = [97, 43113];
  key.Block_URL = "https://testnet.bscscan.com";
  key.COIN_NAME = "BNB";
}

if (Envname === "demo") {
  key.chainlist = [97, 43113];
  key.versionlist = ["0x61", "0xa869"];
  key.chainId = "0xa869";
  key.BACK_V1 = "https://backend-galfi.maticz.in/v1";
  key.COMMON_URL = "https://backend-galfi.maticz.in/v1/common";
  key.BACK_URL = "https://backend-galfi.maticz.in/v1";
  key.AdminAPI = "https://backend-galfi.maticz.in/v1/admin";
  key.CMS = "https://backend-galfi.maticz.in/v1/cms";
  key.EXCHANGE = "https://backend-galfi.maticz.in/v1/exchange";

  key.adminaddress = "0xEA4fE72960C36CA7a9F4E6A107fDfE07a952704E".toLowerCase();
  key.IMG_URL = "https://backend-galfi.maticz.in";
  key.DefaultImg = "/assets/images/avatar/avatar-01.png";
  key.CoverImg = "https://backend-galfi.maticz.in/demobanner.jpg";
  key.chain_Id_List = [97, 43113];
  key.DEADADDRESS = "0x000000000000000000000000000000000000dEaD".toLowerCase();
  key.TradeContract =
    "0xEF747c5efE5e0eb67DCC06896341dfb9DF5f4F29".toLowerCase();
  key.ERC721 = "0x01769d2C31143933cda2680605f4D379c417e793".toLowerCase();
  key.ERC1155 = "0x708390Ff3f96F4F46761319E07c50f0DADC22E66".toLowerCase();
  key.erc20Address = "0x7CAB80ce0E55F46378E493B584eE61aD68878f11".toLowerCase();
  key.RPC_URL = "https://data-seed-prebsc-1-s1.binance.org:8545/";
  key.CHAIN_ID = 97;
  key.chain_Id_List = [97, 43113];
  key.Block_URL = "https://testnet.bscscan.com";
  key.COIN_NAME = "BNB";
}

if (Envname === "stage") {
  key.chainlist = [97, 43113];
  key.versionlist = ["0x61", "0xa869"];
  key.chainId = "0xa869";
  key.BACK_V1 = "https://uat-backend.galfi.io/v1";
  key.COMMON_URL = "https://uat-backend.galfi.io/v1/common";
  key.BACK_URL = "https://uat-backend.galfi.io/v1";
  key.AdminAPI = "https://uat-backend.galfi.io/v1/admin";
  key.CMS = "https://uat-backend.galfi.io/v1/cms";
  key.EXCHANGE = "https://uat-backend.galfi.io/v1/exchange";

  key.adminaddress = "0xEA4fE72960C36CA7a9F4E6A107fDfE07a952704E".toLowerCase();
  key.IMG_URL = "https://uat-backend.galfi.io";
  key.DefaultImg = "/assets/images/avatar/avatar-01.png";
  key.CoverImg = "https://uat-backend.galfi.io/demobanner.jpg";
  key.chain_Id_List = [97, 43113];
  key.DEADADDRESS = "0x000000000000000000000000000000000000dEaD".toLowerCase();
  key.TradeContract =
    "0xEF747c5efE5e0eb67DCC06896341dfb9DF5f4F29".toLowerCase();
  key.ERC721 = "0x01769d2C31143933cda2680605f4D379c417e793".toLowerCase();
  key.ERC1155 = "0x708390Ff3f96F4F46761319E07c50f0DADC22E66".toLowerCase();
  key.erc20Address = "0x7CAB80ce0E55F46378E493B584eE61aD68878f11".toLowerCase();
  key.RPC_URL = "https://data-seed-prebsc-1-s1.binance.org:8545/";
  key.CHAIN_ID = 97;
  key.chain_Id_List = [97, 43113];
  key.Block_URL = "https://testnet.bscscan.com";
  key.COIN_NAME = "BNB";
}

export default key;
