import Config from "../lib/config";

export const isEmpty = (value) =>
  value === undefined ||
  value === null ||
  (typeof value === "object" && Object.keys(value).length === 0) ||
  (typeof value === "string" && value.trim().length === 0);

export const NumANdDotOnly = (data) => {
  data = data.toString();
  let str = data
    ? data.includes(".")
      ? data.split(".").length >= 3
        ? (data.split(".")[0] + "." + data.split(".")[1]).toString()
        :data
      : data
    : data;
  return str.toString().replace(Config.NumDigitOnly, "");
};
export const NumberOnly = (data) => {
  return data.toString().replace(Config.NumberOnly, "");
};


export  const signImageUrl  = (data) => {
  return `${Config.CDN + data}`;
}

export const HandelAxiosError = (err) => {
  if(err.response.status >= 400 && err.response.status < 500){
      if(err.response.status == 422){
      localStorage.clear();  
      return err.response.data     
      }
      return { status : false , message : err.response.data.error}   
  }
  return { status : false , message : 'please try later '}
}


export const IMAGEPATH = ( path , data) => {
  return `${Config.IMAGEPATH + data}`;
}