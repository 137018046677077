import React, { useState, useEffect, useMemo } from "react";
import { Link, useLocation } from "react-router-dom";
import { Trans } from "react-i18next";
import { GrPlan } from "react-icons/gr";
import { FaUserCircle } from "react-icons/fa";
import { FaQuestionCircle } from "react-icons/fa";
import { GoProjectRoadmap } from "react-icons/go";
import { TbBrandAirtable } from "react-icons/tb";
import { CiViewList } from "react-icons/ci";
import { MdOutlineContactPhone } from "react-icons/md";
const Sidebar = () => {
  const location = useLocation();
  const [activeMenu, setActiveMenu] = useState(null);

  const toggleMenuState = (menu) => {
    setActiveMenu(activeMenu === menu ? null : menu);
  };

  useEffect(() => {
    setActiveMenu(null); // Close all menus on route change
  }, [location.pathname]);

  const dropdownPaths = useMemo(
    () => [
      { path: "/apps", state: "appsMenuOpen" },
      { path: "/basic-ui", state: "basicUiMenuOpen" },
      { path: "/form-elements", state: "formElementsMenuOpen" },
      { path: "/tables", state: "tablesMenuOpen" },
      { path: "/icons", state: "iconsMenuOpen" },
      { path: "/charts", state: "chartsMenuOpen" },
      { path: "/user-pages", state: "userPagesMenuOpen" },
      { path: "/error-pages", state: "errorPagesMenuOpen" },
    ],
    []
  );

  const isPathActive = (path) => location.pathname.startsWith(path);

  const NavItem = ({ path, icon: Icon, label }) => (
    <li className="nav-item">
      <Link
        className={`nav-link ${isPathActive(path) ? "active" : ""}`}
        to={path}
      >
        <Trans>
          <Icon className="mr-2" />
          {label}
        </Trans>
      </Link>
    </li>
  );

  return (
    <nav className="sidebar sidebar-offcanvas" id="sidebar">
      <div className="sidebar-brand-wrapper d-none d-lg-flex align-items-center justify-content-center fixed-top">
        <Link className="sidebar-brand brand-logo" to="/plans">
          <img src={require("../../assets/images/logos.png")} alt="logo" />
        </Link>
        <Link className="sidebar-brand brand-logo-mini" to="/plans">
          <img src={require("../../assets/images/sullamlogo.png")} alt="logo" />
        </Link>
      </div>
      <ul className="nav mt-3">
        <div className="accordion" id="accordionExample">
          {[
            {
              title: "NFT MarketPlace Site",
              id: "collapseOne",
              items: [
                { path: "/users", icon: FaUserCircle, label: "Users" },
                {
                  path: "/contactlist",
                  icon: MdOutlineContactPhone,
                  label: "Subscribers",
                },
                {
                  path: "/collectionlist",
                  icon: MdOutlineContactPhone,
                  label: "Collection",
                },
                {
                  path: "/tokenlist",
                  icon: MdOutlineContactPhone,
                  label: "Tokens",
                },
              ],
            },
            {
              title: "CMS",
              id: "collapseTwo",
              items: [
                { path: "/plans", icon: GrPlan, label: "Planet CMS" },
                { path: "/faq", icon: FaQuestionCircle, label: "FAQ" },
                { path: "/cmslist", icon: CiViewList, label: "CMS" },
                {
                  path: "/roadmaplist",
                  icon: GoProjectRoadmap,
                  label: "Roadmap",
                },
              ],
            },
            {
              title: "Game Site",
              id: "collapseThree",
              items: [
                {
                  path: "/shiplist",
                  icon: MdOutlineContactPhone,
                  label: "Ships",
                },
                // { path: "/crewlist", icon: MdOutlineContactPhone, label: "Crew" },
                {
                  path: "/poollist",
                  icon: MdOutlineContactPhone,
                  label: "Hard Stake Pool",
                },
                {
                  path: "/missionrewardlist",
                  icon: MdOutlineContactPhone,
                  label: "Mission Reward",
                },
                {
                  path: "/mission-planet-list",
                  icon: MdOutlineContactPhone,
                  label: "Near By Planet Limit Setting",
                },
                {
                  path: "/game-mission-values",
                  icon: MdOutlineContactPhone,
                  label: "Game Mission Reard Settings ",
                },
                // { path: "/game-settings", icon: MdOutlineContactPhone, label: "Game Settings " },
                {
                  path: "/airdrop",
                  icon: MdOutlineContactPhone,
                  label: "Airdrop",
                },
                // {
                //   path: "/crew-price",
                //   icon: MdOutlineContactPhone,
                //   label: "CrewPrice",
                // },
              ],
            },
            {
              title: "Promotion Site",
              id: "collapseFour",
              items: [
                {
                  path: "/bloglist",
                  icon: MdOutlineContactPhone,
                  label: "Blog List",
                },
                {
                  path: "/newslist",
                  icon: MdOutlineContactPhone,
                  label: "News List",
                },
                {
                  path: "/sociallist",
                  icon: MdOutlineContactPhone,
                  label: "Social List",
                },
                {
                  path: "/partners",
                  icon: MdOutlineContactPhone,
                  label: "Partners",
                },
                {
                  path: "/publishlist",
                  icon: MdOutlineContactPhone,
                  label: "Publish",
                },
                {
                  path: "/promobuildlist",
                  icon: MdOutlineContactPhone,
                  label: "Promo Build",
                },
                {
                  path: "/uploadpdf",
                  icon: MdOutlineContactPhone,
                  label: "WhitePaper",
                },
              ],
            },
          ].map(({ title, id, items }) => (
            <div className="accordion-item" key={id}>
              <h2 className="accordion-header">
                <button
                  className={`accordion-button ${
                    activeMenu === id ? "" : "collapsed"
                  }`}
                  type="button"
                  onClick={() => toggleMenuState(id)}
                  aria-expanded={activeMenu === id}
                >
                  {title}
                </button>
              </h2>
              <div
                id={id}
                className={`accordion-collapse collapse ${
                  activeMenu === id ? "show" : ""
                }`}
                data-bs-parent="#accordionExample"
              >
                <div className="accordion-body pt-2">
                  <ul className="nav flex-column sub-menu pl-3">
                    {items.map(({ path, icon, label }) => (
                      <NavItem
                        key={path}
                        path={path}
                        icon={icon}
                        label={label}
                      />
                    ))}
                  </ul>
                </div>
              </div>
            </div>
          ))}
        </div>
      </ul>
    </nav>
  );
};

export default Sidebar;
